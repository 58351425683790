/*$(window).scroll(function() {
    if ($(this).scrollTop() > 75){  
        $('header').addClass("sticky");
    }
    else{
        $('header').removeClass("sticky");
    }
});
*/
//$(document).ready(function () {
$('#nav-open-btn2').click(function(){
    $(".slide-menu").animate({ "left": "+=270px" }, "slow" );
});
$("#nav-close-btn").click(function(){
    $(".slide-menu").animate({ "left": "-=270px" }, "slow" );
});

/*if ($(window).width() > 799) {
			$('#fixedHeaderClose').css('margin-top', $('header').height() + 'px');
		}

*/
//	});
var stickyLeaderboardInit = false;
function stickyLeaderboard() {
		if(!stickyLeaderboardInit){
			stickyLeaderboardInit = true;
			var totalHeaderHeight = $('.top-ad').outerHeight() + $('header').outerHeight();
			if ($(window).scrollTop() < $('.top-ad').outerHeight()) {
				$(".fixed.top").css({'position':'relative'});
				$("header").css({'position':'relative','margin-top':'0'});
				$('#fixedHeaderClose').css('margin-top', '0');
				$("header").removeClass('unstuck');
			} else {
				$('.top-ad').slideUp(300,function(){
					$(".fixed.top").css({'position':'relative'});
					$('.top-ad').show();
					$("header").css({'position':'fixed','margin-top':'0'});
					$('#fixedHeaderClose').css('margin-top', $('header').outerHeight() + 'px');
					if($(window).scrollTop() < totalHeaderHeight){
						$("header").removeClass('unstuck');
					}else{
						$("header").addClass('unstuck');
					}
				});
			}
			$(window).scroll(function() {
				if($(window).width() > 800){
					if ($(window).scrollTop() < $('.top-ad').outerHeight()) {
						$("header").css({'position':'relative'});
						$('#fixedHeaderClose').css('margin-top', '0');
						$("header").removeClass('unstuck');
						totalHeaderHeight = $('.fixed.top').outerHeight();
					} else {
						if($(window).scrollTop() < totalHeaderHeight){
							$("header").removeClass('unstuck');
						}else{
							$("header").addClass('unstuck');
						}
						$("header").css({'position':'fixed'});
						$('#fixedHeaderClose').css('margin-top', $('header').outerHeight() + 'px');
					}
				}
			});
		}
	}

//rob added this

// Hide Header on on scroll down
	var didScroll;
	var lastScrollTop = 0;
	var delta = 100;
	var navbarHeight = $('.top-ad').outerHeight() + $('header').outerHeight();

	$(window).scroll(function(event) {
		didScroll = true;
	});

	setInterval(function() {
		if (didScroll) {
			hasScrolled();
			didScroll = false;
		}
	}, 250);

	function hasScrolled() {
		var st = $(this).scrollTop();

		// Make sure they scroll more than delta
		if (Math.abs(lastScrollTop - st) <= delta)
			return;

		// If they scrolled down and are past the navbar, add class .nav-up.
		// This is necessary so you never see what is "behind" the navbar.
		if (st > lastScrollTop && st > navbarHeight) {
			// Scroll Down
			$('header').removeClass('nav-down').addClass('nav-up');
		} else {
			// Scroll Up
			if (st + $(window).height() < $(document).height()) {
				$('header').removeClass('nav-up').addClass('nav-down');
			}
		}
		lastScrollTop = st;
	}
/// end rob added
